import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	ContentSection,
	HeaderSection,
	InlineMessage,
	Code,
	ImportExample,
	Header,
} from "../../../../../src/docs";
import image from "../../../../../src/docs/example-img.png";
import { Ul } from "../../text/list/list";
import { Paragraph } from "../../text/paragraph/paragraph";
import { TextContainer } from "../../text/text-container/text-container";
import { Badge } from "../../../../dist";
import { Link, LinkContainer } from "./link";

export const Meta: DocPageMeta = {
	category: "Navigation",
	title: "Link",
	notepad: "https://hackmd.io/lzvQQxlOSt2pJQCQ244Nvg",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Link"
			subTitle="A link enables users to navigate to a different page, page section, or site."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={Link} />
				<InlineMessage variant="warning">
					<Paragraph>
						If your link answers the question "What can I do?", instead of "Where can I go?", use
						the <GatsbyLink to="/lib/components/Actions and controls/Button">button</GatsbyLink>{" "}
						component instead. Its <Code>href</Code> property enables it to behave like a link, but
						look like a button.
					</Paragraph>
				</InlineMessage>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The link component features various properties for adjusting its appearance. The following
					sections showcase these properties and explain when to use them.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					Use the link's default appearance when it's presented by itself. That is, when it's not
					part of a sentence or a phrase. It features an arrow to indicate that it's a link without
					relying on color alone.
				</Paragraph>
			</TextContainer>
			<Example fn={defaultExample} />
			<TextContainer article>
				<Header.H3>Size</Header.H3>
				<Paragraph>
					Use this property to adjust how attention-grabbing a standalone link should be.
				</Paragraph>
			</TextContainer>
			<Example fn={sizeExample} />
			<TextContainer article>
				<Header.H3>Inline</Header.H3>
				<Paragraph>
					Use this variant when the link is part of a sentence or a phrase. It features an
					underline, instead of an arrow, to indicate without color that it is a link. This variant
					inherits its size from the text it's part of.
				</Paragraph>
			</TextContainer>
			<Example fn={inlineExample} />
			<TextContainer article>
				<Header.H3>Unstyled</Header.H3>
				<Paragraph>
					Use this property when an interface element, such as an image, needs to behave like a
					link, but should not be styled like a standalone or inline link. Its custom styling and
					the UI context in which it's used should make clear that it's a link and where it links
					to.
				</Paragraph>
			</TextContainer>
			<Example fn={unstyledExample} />
			<TextContainer article>
				<Header.H3>Open in new tab or window</Header.H3>
				<Paragraph>
					When a link points to a page on another domain, it's generally best to open it in a new
					tab or window (depending on the user's browser setting). This can be achieved by setting
					the <Code>openNew</Code> property to <Code>true</Code>.
				</Paragraph>
			</TextContainer>
			<Example fn={openNewExample} />
			<TextContainer article style={{ marginBottom: "2.5rem" }}>
				<Header.H3>Download linked resource</Header.H3>
				<Paragraph>
					When a link leads to a resource intended to be downloaded rather than displayed in the
					browser the <Code>download</Code> property can be set to <Code>true</Code>. Provide a
					string value to specify the default file name like so:{" "}
					<Code>download="filename.pdf"</Code>. Best practice is to not open a link, intendded to be
					downloaded, in a new tab, so avoid using <Code>openNew</Code> and <Code>download</Code>{" "}
					together.
				</Paragraph>
			</TextContainer>
			<TextContainer article>
				<Header.H3>Link container and Link text</Header.H3>
				<Paragraph>
					If you want some of the content to not have the link text styling but still be part of the
					link.
				</Paragraph>
			</TextContainer>
			<Example fn={linkContainerExample} />
			<TextContainer article>
				<Header.H2>Properties</Header.H2>
			</TextContainer>
			<Knobs component={Link} initialProps={{ href: "https://www.google.com", children: "Link" }} />
			<TextContainer article>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							"Use when the user needs to navigate to a different site, page, or page section.",
							"Use the default variant when the link is presented by itself.",
							"Use the inline variant when the link is part of a sentence or phrase.",
							"Use the unstyled variant when an interface element, such as an image, needs to behave like a link, but should not be styled like a link (or button).",
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								The link needs to look like a button. Use the{" "}
								<GatsbyLink to="/lib/components/Actions and controls/Button#link">
									button
								</GatsbyLink>{" "}
								component instead and configure its properties, so that it functions like a link.
								However, only do this if the button “feels” like a button to the user. It should
								answer the question “What can I do?”, not “Where can I go?”.
							</>,
							<>
								The user needs to toggle between different views. Use{" "}
								<GatsbyLink to="/lib/components/Navigation/Tabs">tabs</GatsbyLink> or a{" "}
								<GatsbyLink to="/lab/components/Actions and controls/Toggle Group">
									toggle group
								</GatsbyLink>{" "}
								instead.
							</>,
							<>
								The user needs to toggle between two opposing states with immediate effect. Use a{" "}
								<GatsbyLink to="/lab/components/Actions and controls/Toggle Switch">
									toggle switch
								</GatsbyLink>{" "}
								instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Ul
						items={[
							"Don't have two links to the same destination next to each other. For example: an icon with a link, and some text with the same link next to it.",
							<>
								Avoid generic link texts ("Click here", "Read more", "Learn more", etc.). The link
								should be able to stand on its own.
							</>,
						]}
					/>
					<Paragraph>
						The component’s implementation ensures that it automatically follows these guidelines:
					</Paragraph>
					<Ul
						items={[
							<>
								Pressing the <Code>Enter</Code> key executes the link and moves focus to the link
								target.
							</>,
							<>
								Add <Code>role=“link”</Code> to the element containing the link.
							</>,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Be descriptive and specific.,",
							"Front-load the link text with relevant terms.",
							<>
								Tell users if the link takes you away from the platform (e.g. “You can learn more
								about{" "}
								<Link inlineInText href="#writing">
									HTTP status codes
								</Link>{" "}
								in our Help Center.” )
							</>,
							<>
								Refer to the page title in the text if the link is to a page (e.g.{" "}
								<Link inlineInText href="#writing">
									Integrations Overview
								</Link>{" "}
								instead of{" "}
								<Link inlineInText href="#writing">
									See an overview of your integrations
								</Link>
								).
							</>,
							"Use markdown to insert links. It’s better for localization if we don’t concatenate strings.",
							<>
								Don't include closing punctuation unless the link is phrased as a question; in that
								case, close with a question mark (e.g.{" "}
								<Link inlineInText href="#writing">
									Forgot your password?
								</Link>
								)
							</>,
							"Don't use a URL as link text (unless, of course, it's a broken link).",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const defaultExample = () => (
	<Link href="https://www.google.com">Medium standalone link (default)</Link>
);

const sizeExample = () => (
	<>
		<Link href="https://www.google.com" size="small">
			Small standalone link
		</Link>
		<br />
		<Link href="https://www.google.com" size="medium">
			Medium standalone link (default)
		</Link>
		<br />
		<Link href="https://www.google.com" size="large">
			Large standalone link
		</Link>
		<br />
	</>
);

const inlineExample = () => (
	<Paragraph>
		This is an example of an{" "}
		<Link href="https://www.google.com" inlineInText>
			inline link
		</Link>
		, which is part of a sentence.
	</Paragraph>
);

/** @ignore due to image reference */
const unstyledExample = () => (
	<Link href="https://www.google.com" unstyled>
		<img src={image} alt="some image with link" height="40" />
	</Link>
);

const openNewExample = () => (
	<Link href="https://www.google.com" openNew>
		Medium standalone link (default)
	</Link>
);

const linkContainerExample = () => (
	<LinkContainer href="https://www.google.com" openNew>
		<Badge>Pre:</Badge> <LinkContainer.LinkText>Styled link text</LinkContainer.LinkText>
	</LinkContainer>
);
